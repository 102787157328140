import React from "react";
import Hero from "../Hero";
import WorkingProcess from "../Section/WorkingProcess";
import TestimonialSectionStyle4 from "../Section/TestimonialSection/TestimonialSectionStyle4";

import Section from "../Section";

import FaqSection from "../Section/FaqSection";

import AboutSectionStyle4 from "../Section/AboutSection/AboutSectionStyle4";
import DepartmentSectionStyle6 from "../Section/DepartmentSection/DepartmentSectionStyle6";

import { Link } from "react-router-dom";
import { pageTitle } from "../../helpers/PageTitle";

const testimonialData = [
  {
    text: '"Très bon suivi de mon dossier avec mon conseiller Julien Muller.”',
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_2.png",
    avatarName: "Aurélie Az",
    avatarDesignation: "01.2024",
  },
  {
    text: "\"Service Très réactif, Mme Lambert est toujours à l'écoute...”",
    ratingNumber: "4",
    avatarImgUrl: "/images/home_4/avatar_3.png",
    avatarName: "Stéphane",
    avatarDesignation: "02.2024",
  },
  {
    text: "“Des tarifs compétitifs j'ai réalisé 300 euros d'économies. Merci Diapzone.”",
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_4.png",
    avatarName: "Claudine",
    avatarDesignation: "02.2024",
  },
  {
    text: "\"La dame que j'ai eu par téléphone m'a bien renseigné, elle a pris le temps de m'expliquer et de m'accompagner dans toutes les démarches. Merci.”",
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_2.png",
    avatarName: "Florence",
    avatarDesignation: "01.2024",
  },
  {
    text: '"Je suis très satisfaite, rapide, et simple. les prix sont corrects. Merci”',
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_3.png",
    avatarName: "Chantal",
    avatarDesignation: "03.2024",
  },
  {
    text: "“Je suis satisfait du service, les prix me convient, les conseillers sont toujours aimable et prennent en charge mes demandes.”",
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_4.png",
    avatarName: "Hervé",
    avatarDesignation: "03.2024",
  },
];
const workingProcessData = [
  {
    title: "Parlez-nous de vous",
    subTitle:
      "En quelques clics, renseignez vos besoins et votre budget dans notre formulaire simple et intuitif",
    iconUrl: "/images/home_2/wording_process_icon_1.svg",
    number: "01",
  },
  {
    title: "Découvrez les meilleures offres",
    subTitle:
      "Nous analysons et comparons pour vous les meilleures offres du marché pour trouver votre protection idéale",
    iconUrl: "/images/home_2/wording_process_icon_2.svg",
    number: "02",
  },
  {
    title: "Choisissez en toute confiance",
    subTitle:
      "Sélectionnez et souscrivez à votre nouveau contrat d'assurance santé en toute simplicité",
    iconUrl: "/images/home_2/wording_process_icon_3.svg",
    number: "03",
  },
  {
    title: "Laissez-nous faire",
    subTitle:
      "Nous gérons tout pour vous : résiliation de votre ancien contrat et démarches administratives",
    iconUrl: "/images/home_2/wording_process_icon_4.svg",
    number: "04",
  },
  {
    title: "Profitez de notre accompagnement",
    subTitle:
      "Bénéficiez d'un suivi personnalisé et d'une assistance dédiée tout au long de votre contrat",
    iconUrl: "/images/home_2/wording_process_icon_5.svg",
    number: "05",
  },
];

const faqData = [
  {
    title: "Pourquoi la complémentaire santé est-elle importante ?",
    content:
      "Aujourd’hui, il devient indispensable de souscrire une mutuelle avec des bonnes garanties pour pallier ses frais de santé. La Sécurité sociale prend, certes, en charge une partie des dépenses mais ses remboursements sont bien souvent insuffisants. Par exemple, pour les soins optiques, les remboursements sont très dérisoires. Sans complémentaire santé, votre reste à charge sur vos dépenses médicales sera très élevé.",
  },
  {
    title:
      "Comment puis-je être sûr(e) de choisir la mutuelle qui me convient le mieux ?",
    content:
      "Diapazone propose un service unique avec des experts santé dédiés. Après avoir rempli votre profil, vous avez la possibilité de bénéficier d'un entretien personnalisé avec un expert santé qui vous guidera tout au long du processus de la comparaison à la souscription et répondra à toutes vos questions.",
  },
  {
    title: "Comment Résiler ma mutuelle ?",
    content:
      "Il est possible de résilier votre mutuelle à l’échéance annuelle de votre contrat en respectant un préavis de deux mois si votre adhésion date de moins d’un an. Ou, après une année de contrat, à tout moment, sans frais ni justificatif. Nos services s’occupent de toutes les démarches pour vous gratuitement.",
  },
  {
    title: "Quelle est la mutuelle la moins chère ?",
    content:
      "Le choix dépend entièrement de votre profil et de vos besoins en santé. Plutôt que de privilégier la recherche de la mutuelle la moins coûteuse, nous vous suggérons de mettre l'accent sur celle qui offre le meilleur équilibre entre garanties et prix.",
  },
];

const departmentData = [
  {
    title: "Service 100% Gratuit",
    iconUrl: "images/home_1/department_icon_1.svg",
    href: "/departments/department-details",
  },
  {
    title: "Jusqu'à 470 euros* d'économies",
    iconUrl: "images/home_1/department_icon_2.svg",
    href: "/departments/department-details",
  },
  {
    title: "Accompagnement 7/7",
    iconUrl: "images/home_1/department_icon_3.svg",
    href: "/departments/department-details",
  },
  {
    title:
      "La résiliation de votre ancien contrat gratuitement et Mise en place du nouveau contrat",
    iconUrl: "images/home_1/department_icon_4.svg",
    href: "/departments/department-details",
  },
  {
    title: "Une Équipe de Professionnels Dévoués",
    iconUrl: "images/home_1/department_icon_5.svg",
    href: "/departments/department-details",
  },
  {
    title: "Des Valeurs Fondamentales Solides",
    iconUrl: "images/home_1/department_icon_6.svg",
    href: "/departments/department-details",
  },
];

export default function Home() {
  pageTitle("Home");
  return (
    <>
      <Hero
        title="Votre Santé, Notre Priorité: Une Protection Sur Mesure"
        subTitle="La Protection Santé Qui S'adapte à Votre Budget. Une couverture santé complète et personnalisée pour une tranquillité d'esprit totale"
        bgUrl="/images/home_1/hero_bg.png"
        imgUrl="/images/home_1/hero_img.png"
        // Remove videoBtnText and videoUrl
        // videoBtnText="See how we work"
        // videoUrl="https://www.youtube.com/embed/VcaAVWtP48A"
        infoList={[
          {
            title: "Ligne d'assistance",
            subTitle: "01.84.80.40.37",
            iconUrl: "/images/contact/icon_1.svg",
          },
          {
            title: "Email",
            subTitle: "info@diapazone.fr",
            iconUrl: "/images/icons/ambulance.svg",
          },
          {
            title: "Location",
            subTitle: "16 RUE CUVIER 69006 LYON, FRANCE.",
            iconUrl: "/images/icons/pin.svg",
          },
        ]}
        // btnText="Book Now"
        // btnUrl="/appointments"
        customButton={
          <Link to="/tarif" className="cs_obtenir_tarif_btn">
            J'obtiens mon tarif
          </Link>
        }
      />
      {/* <div className="cs_section_spacing"></div> */}
      <Section id="apropos" topMd={170} topLg={130} topXl={85}>
        <AboutSectionStyle4
          imgUrl="/images/home_3/about.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          titleUp="À propos de nous"
          title="Diapazone, votre partenaire de confiance pour une protection d'assurance fiable et personnalisée."
          subTitle="Chez Diapazone, notre engagement premier est d'offrir à nos clients une tranquillité d'esprit totale en matière d'assurance. Nous comprenons que chaque individu, chaque famille, chaque entreprise a des besoins uniques, c'est pourquoi nous nous efforçons de créer des solutions d'assurance sur mesure qui s'adaptent précisément à vos exigences. Que vous soyez un particulier une famille ou un travailleur non salarié, nous avons une gamme complète de produits d'assurance pour répondre à vos besoins spécifiques. Notre objectif est de vous fournir la protection dont vous avez besoin."
        />
      </Section>
      <Section topMd={190} topLg={150} topXl={105} id="departments">
        <DepartmentSectionStyle6
          sectionTitle="On s'occupe de tout!"
          sectionTitleUp="Pourquoi nous choisir?"
          data={departmentData}
        />
      </Section>

      {/* <Section topMd={190} topLg={150} topXl={105}>
        <Cta
          title="Et Si Vous Économisiez sur Votre Assurance Santé ?"
          subTitle="Trouvez la meilleure couverture santé adaptée à vos besoins et à votre budget. Comparez gratuitement les offres de nos partenaires en quelques clics."
          bgUrl="/images/home_4/cta_bg.jpg"
          btnUrl="/appointments"
          btnText="Obtenir votre tarif"
        />
      </Section> */}
      <Section
        id="ccc"
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <WorkingProcess
          sectionTitle="Trouvez Votre Assurance en 3 Minutes"
          sectionTitleUp="Comment ça marche ? "
          sectionTitleDown=""
          sectionSubTitle=""
          data={workingProcessData}
        />
      </Section>
      <Section
        id="ac"
        topMd={140}
        topLg={100}
        topXl={55}
        bottomMd={100}
        bottomLg={50}
        bottomXl={10}
      >
        <TestimonialSectionStyle4
          sectionTitle="Plus de 5000 clients nous font confiance"
          sectionTitleUp="97% de clients satisfaits"
          avatarListTitle="150+ patients are reviews about ProHealth"
          avatarList={[
            { imgUrl: "/images/home_4/avatar_1.png" },
            { imgUrl: "/images/home_4/avatar_2.png" },
            { imgUrl: "/images/home_4/avatar_3.png" },
            { imgUrl: "/images/home_4/avatar_4.png" },
            { imgUrl: "/images/home_4/avatar_5.png" },
          ]}
          data={testimonialData}
        />
      </Section>
      <Section id="faq" topMd={80} topLg={60} topXl={40}>
        <FaqSection
          data={faqData}
          sectionTitle="à vos questions!"
          sectionTitleUp="On répond"
        />
      </Section>
      <div className="cs_section_spacing"></div>
      <div className="cs_section_spacing"></div>
    </>
  );
}
