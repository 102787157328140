// In FormPageNewDesign.jsx
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../oldForm/theme"; // copy your old theme.js into oldForm folder
import Form from "../../oldForm/containers/Form";

const FormPageNewDesign = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="old-form-container">
        <Form />
      </div>
    </ThemeProvider>
  );
};

export default FormPageNewDesign;
