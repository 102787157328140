import React from "react";
import TextWidget from "../Widget/TextWidget";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import SocialWidget from "../Widget/SocialWidget";
import MenuWidget from "../Widget/MenuWidget";
import NewsletterStyle4 from "../Widget/NewsletterStyle4";
const menuDataOne = [
  { title: "À propos de nous", href: "/about" },
  { title: "Pourquoi nous choisir?", href: "/departments" },
  { title: "Comment ça marche", href: "/doctors" },
];

const menuDataTwo = [
  { title: "Avis clients", href: "/" },
  { title: "FAQ", href: "/blog" },
  { title: "Obtenir un tarif", href: "/" },
];
const menuDataThree = [
  { title: "Mentions légales", href: "/about" },
  { title: "CGV", href: "/departments" },
  { title: "Conditions d'utilisation", href: "/doctors" },
];

export default function FooterStyle4() {
  return (
    <footer className="cs_footer cs_style_2 cs_type_1 cs_mamamia_bg cs_black_color">
      <div className="container">
        <NewsletterStyle4 title="Avez-vous d'autres questions ?" subTitle="" />
        <div className="cs_footer_in">
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <TextWidget
                logoUrl="/images/footer_logo_white.svg"
                text=" Diapazone est une marque exploitée par <br> OLM Assurances, immatriculée sous le <br>  SIREN 922287735"
              />
              <ContactInfoWidget />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataOne} />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataTwo} />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataThree} />
              <SocialWidget />
            </div>
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom">
        <div className="container">
          <div className="cs_copyright">
            Copyright © 2024 Diapazone. Tous droits réservés.
          </div>
        </div>
      </div>
    </footer>
  );
}
