import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function HeaderFormVariant({ logoSrc }) {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`cs_site_header cs_style1 ${
        isSticky ? "cs_active_sticky" : ""
      }`}
      style={{ backgroundColor: "#fff" }}
    >
      <div className="cs_main_header">
        <div className="container">
          <div
            className="cs_main_header_in"
            style={{ justifyContent: "center" }}
          >
            {/* Logo Only */}
            <Link className="cs_site_branding" to="/">
              <img src={logoSrc} alt="Logo" style={{ maxHeight: "60px" }} />
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}
